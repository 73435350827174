(function nav() {
  const toggle = document.querySelector("#js-menu-toggle");
  const drawer = document.querySelector(".drawer--nav");
  const nav = document.querySelector("nav");
  const links = document.querySelectorAll(".drawer__link");
  let width = document.documentElement.clientWidth;

  function toggleDrawer() {
    document.body.classList.toggle("no-scroll-y");
    drawer.classList.toggle("drawer--open");
    drawer.classList.toggle("drawer--closed");
    nav.classList.toggle("nav--open");
    toggle.classList.toggle("menu-toggle--open");
  }

  toggle.addEventListener("click", function() {
    toggleDrawer();
  });
  // Close window if
  links.forEach(link => {
    link.addEventListener("click", function() {
      toggleDrawer();
    });
  });

  // @todo fix window so drawer closes if open when resized beyond the sm breakpoint
  // window.addEventListener("resize", function() {
  //   var width = document.documentElement.clientWidth;
  //   if (width > 666 ||  ) {
  //     console.log(w);
  //     toggleDrawer();
  //   }
  // });
})();
