const contactForm = document.querySelector("#contact");
let validate = new Bouncer("#contact form", {
  emitEvents: true,
  disableSubmit: true
});

if (contactForm !== undefined && contactForm !== null) {
  document.addEventListener("bouncerFormValid", function(event) {
    ajaxSubmit(event, "/thank-you");
  });
}

function ajaxSubmit(e, dest) {
  const form = e.target;
  const data = new FormData(form);
  const xhr = new XMLHttpRequest();
  xhr.open(form.method, form.action);
  xhr.send(data);
  xhr.onload = () => {
    if (xhr.readyState === xhr.DONE) {
      redirectTo(dest);
    }
  };
}

function redirectTo(dest) {
  window.location.href = dest;
}
