const cards = document.querySelectorAll(".brand-card");

let options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.2
};

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else
    el.className = el.className.replace(
      new RegExp("\\b" + className + "\\b", "g"),
      ""
    );
}

function intersect(entries, observer) {
  entries.forEach(entry => {
    if (entry.intersectionRatio > options.threshold) {
      removeClass(entry.target, "unloaded");
      window.clearTimeout(loadDelay);
    }
  });
}

let observer = new IntersectionObserver(intersect, options);

let loadDelay = window.setTimeout(function() {
  cards.forEach(x => observer.observe(x));
}, 500);
