// Load and init polyfills
// These don't support ES6 or CommonJS imports so they are loaded via CDN in footer for now.
// import "../node_modules/smooth-scroll/dist/smooth-scroll.polyfills";
// import "../node_modules/formbouncerjs/dist/bouncer.polyfills";
import "intersection-observer"; // loaded via NPM
var scroll = new SmoothScroll('a[href*="#"]');

import "./modules/nav";
import "./modules/form";
import "./modules/cardImageSwapper";
import "./modules/cardLoader";
