import { debounce } from "../helpers.js";

const cards = document.querySelectorAll(".brand-card__images");

cards.forEach(card => {
  let delay = 600;
  let array = Array.from(card.querySelectorAll(".brand-card__image"));
  let throttle;
  array.forEach(function(item, index) {
    let next;
    if (index === array.length - 1) {
      next = array[0];
    } else {
      next = array[index + 1];
    }
    item.current = item;
    item.next = next;
  });

  card.addEventListener("mousemove", function(event) {
    throttle = setTimeout(function() {
      swap(event.target.current, event.target.next);
      setTimeout(() => {
        clearTimeout(throttle);
      }, delay);
    }, delay);
  });
  card.addEventListener("mouseout", clearTimeout(throttle));
});

function swap(current, next) {
  current.classList.remove("active");
  next.classList.add("active");
}
